.layout-main-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    justify-content: space-between;
    padding: 7rem 2rem 2rem 2rem;
    transition: margin-left $transitionDuration;
}

.layout-main-containers {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 6rem 2rem 2rem 2.4rem;
    transition: margin-left $transitionDuration;
}

.layout-main {
    flex: 1 1 auto;
    margin-top: 1.714285714285714rem;
}
