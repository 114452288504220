/* General */
$fontSize: 10px;
$borderRadius: 12px;
$transitionDuration: 0.2s;
$borderRadius-header: 12px 12px 0px 0px;
$borderRadius-footer: 0px 0px 12px 12px;

$primary-blue: #0e8ec5;
$primary-green: #54b486;
$primary-red: #d85454;
$primary-yellow: #f6cc34;
$primary-gray: #656565;
$ligth-yellow: #ffffe8;
$light-blue: #e8f2ff;
$light-blue-quarter: #f6fafc;
$light-green: #d5fede;
$light-red: #ffe8e8;
$light-yellow: #ffffe8;
$light-gray: #9f9f9f;
$dark-red: #b84848;
$dark-green: #469971;
$dark-yellow: #ddb72e;
$border-input-color: #e7e6e6;
$text-primary: #656565;
$text-secondary: #9f9f9f;
$card-content-bg: #F3F5FF;
